import React from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import Heading from '../components/Heading';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

/*
  Rota Privada/Autenticado

  true/true = OK
  true/false = Redirecionar pro login
  false/true = Redirecionar pro dashboard
  false/false = Ok

  com isso da pra fazer a verificação
  isPrivade === user

  Porque se for igual ok, caso contrário é preciso redirecionar
*/

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return isPrivate === !!user ? (
          <>
            {isPrivate && <Heading />}
            <Component />
          </>
        ) : (
          <Redirect to={{ pathname: isPrivate ? '/' : '/dashboard' }} />
        );
      }}
    />
  );
};

export default Route;
