import { useContext, useEffect, useState } from 'react';
import { createContext, ReactNode } from 'react';
import Condominium from '../pages/Condominium';
import api from '../services/apiClient';

interface Condominium {
  id: string;
  cnpj: string;
  name: string;
  zip_code: string;
  address: string;
  number: string;
  city: string;
  county: string;
  complement: string;
}

// type CondominiumInput = Omit<Condominium, 'id'>;

interface CondominiumContextData {
  condominiums: Condominium[];
  createCondominium: (condominium: Condominium) => Promise<void>;
  deleteCondominium: (id: string) => Promise<void>;
}

interface CondominiumProviderProps {
  children: ReactNode;
}

const CondominiumContext = createContext<CondominiumContextData>(
  {} as CondominiumContextData,
);

export function CondominiumProvider({ children }: CondominiumProviderProps) {
  const [condominiums, setCondominiums] = useState<Condominium[]>([]);

  useEffect(() => {
    api.get('/condominiums').then(response => setCondominiums(response.data));
  }, []);

  async function createCondominium(condominiumInput: Condominium) {
    const response = await api.post('/condominiums', condominiumInput);

    const condominium = response.data;

    const condominiumAlready = condominiums.some(
      condo => condo.id === condominium.id,
    );

    if (condominiumAlready) {
      const newCondominiums = condominiums.filter(
        condo => condo.id !== condominium.id,
      );

      setCondominiums([...newCondominiums, condominium]);
    } else {
      setCondominiums([...condominiums, condominium]);
    }
  }

  async function deleteCondominium(id: string) {
    await api.delete('/condominiums', { data: { id } });

    const condos = condominiums.filter(condo => condo.id !== id);

    setCondominiums(condos);
  }

  return (
    <CondominiumContext.Provider
      value={{ condominiums, createCondominium, deleteCondominium }}
    >
      {children}
    </CondominiumContext.Provider>
  );
}

export function useCondominiums() {
  const context = useContext(CondominiumContext);

  return context;
}
