import styled from 'styled-components';
import { Container as Input } from '../../components/Input/styles';
import { Container as Button } from '../../components/Button/styles';

export const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2.5rem 1rem;

  ${Button} {
    max-width: 8rem;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      padding: 1rem;
    }

    ${Input} {
      max-width: 40rem;
    }

    ${Button} {
      max-width: 40rem;
    }
  }

  table {
    width: 100%;
    border-spacing: 0 0.5rem;

    th {
      color: var(--text-body);
      font-weight: 400;
      padding: 1rem 2rem;
      line-height: 1.5rem;
      text-align: left;

      &:last-child {
        text-align: center;
      }
    }

    td {
      padding: 1rem 2rem;
      border: 0;
      background: #d9d9d9; //#3e4e5e;
      /* border-radius: 0.25rem; */
      line-height: 1.5rem;
      text-align: left;

      &:last-child {
        text-align: center;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      &:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    button {
      background: transparent;
      border: 0;

      & + button {
        margin-left: 0.25rem;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
`;
