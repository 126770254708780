import { shade } from 'polished';
import styled from 'styled-components';
import { Container as Input } from '../../components/Input/styles';

export const Container = styled.div`
  /*height: 100vh;
   display: flex;
  flex-direction: column;
  align-items: stretch; */

  > header {
    height: 200px;
    background: #28262e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 64px;

    svg {
      color: #999591;
      width: 24px;
      height: 24px;
      cursor: pointer;
      /* margin: 0 auto; */
    }
  }
`;

export const Content = styled.div`
  display: flex;

  flex-direction: column;
  place-content: center;
  margin: -273px auto 0;

  align-items: center;

  width: 100%;
  max-width: 700px;

  form {
    margin: 80px 0;
    width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 20px;
      text-align: left;
      margin-bottom: 24px;
    }

    ${Input} {
      & + ${Input} {
        margin-top: 0.5rem;
      }
    }
  }
`;

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  /* width: 186px; */

  align-self: center;

  img {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }

  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #3ea7a2;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    border: 0;
    transition: background-color 0.2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    input {
      display: none;
    }

    svg {
      width: 24px;
      height: 24px;
      color: #312e38;
    }

    &:hover {
      background: ${shade(0.2, '#3ea7a2')};
    }
  }
`;
