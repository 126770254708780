import styled from 'styled-components';
import { shade } from 'polished';

export const Header = styled.header`
  //background: ${shade(-0.1, '#312e34')}; //#28262e; //rgb(32, 32, 36); //
  background: var(--secundary);

  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.5);
`;

export const HeaderContainer = styled.div`
  display: flex;
  height: 90px;
  max-width: 1120px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  > img {
    margin: 16px;
    height: 72px;
  }
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  //color: #696975;
  color: var(--text-body);
  /* margin-left: auto; */

  strong {
    color: #3ea7a2;
    line-height: 24px;
  }

  img {
    margin-left: 8px;
    border-radius: 50%;
    height: 64px;
    width: 64px;
    padding: 2px;
    border: 2px solid rgb(225, 225, 230);
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: var(--primary);

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const Menu = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;

  list-style-type: none;

  ul {
    margin: 0 16px;

    button {
      border: none;
      background: transparent;
      font-size: 18px;
      color: var(--text-body);

      &:hover {
        color: #fff;
      }
    }
  }
`;
