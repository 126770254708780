import styled from 'styled-components';
import { shade } from 'polished';
import ArrowLeftIcon from '../../assets/ArrowLeftIcon.svg';
import ArrowRightIcon from '../../assets/ArrowRightIcon.svg';

export const Container = styled.div``;

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
`;

export const Schedule = styled.aside`
  flex: 1;
  margin-left: 120px;

  h2 {
    /* line-height: 26px; */
    border-bottom: 1px solid #3e3b47;
    /* display: block; */
    padding-bottom: 16px;
    /* margin-bottom: 16px; */
  }
`;

export const NextAppointments = styled.div`
  margin-top: 64px;

  > strong {
    color: #999591;
    font-size: 20px;
    font-weight: 400;
  }

  div {
    background: #3e3b47;
    display: flex;
    align-items: center;
    padding: 0 24px;
    border-radius: 10px;
    margin-top: 24px;
    position: relative;

    &::before {
      position: absolute;
      height: 80%;
      width: 2px;
      left: 0;
      top: 10%;
      content: '';
      background: #3ea7a2;
    }

    img {
      height: 90px;
      width: 90px;
      margin: 8px;
      border-radius: 24px;
    }

    strong {
      /* flex: 1; */
      margin-left: 24px;
      color: #fff;
    }

    span {
      display: flex;
      align-items: center;
      margin: 8px;
      margin-left: auto;
      color: #999591;
      font-size: 18px;

      svg {
        color: #3ea7a2;
        margin: 0 8px;
      }
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #999591;
  }
`;

export const Appointment = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 16px;
  }

  span {
    display: flex;
    align-items: center;
    margin: 4px;
    width: 140px;

    color: #f4ede8;
    font-size: 16px;

    svg {
      color: #3ea7a2;
      margin: 0 8px;
    }
  }

  div {
    background: #3e3b47;
    display: flex;
    align-items: center;
    flex: 1;
    padding: 16px, 24px;
    border-radius: 10px;
    margin-left: 24px;

    img {
      height: 70px;
      width: 70px;
      margin: 8px;
      border-radius: 24px;
    }

    p {
      flex: 1;
      margin-left: 24px;
      color: #fff;
      font-size: 14px;
    }

    button {
      background-color: transparent;
      border: none;
      margin-right: 24px;

      svg {
        color: #f44;
        height: 18px;
        width: 18px;
      }
    }
  }
`;

export const Calendar = styled.div`
  width: 380px;

  h3 {
    margin-bottom: 8px;
  }

  p {
    margin: 8px 0;
    color: #3ea7a2;
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }

    span + span::before {
      content: '';
      width: 1px;
      height: 12px;
      background: #3ea7a2;
      margin: 0 8px;
    }
  }

  .DayPicker {
    border-radius: 10px;
    width: 100%;
    margin: 16px 0;

    &-wrapper {
      padding-bottom: 0;
      background: #28262e;
      border-radius: 10px;
      z-index: 0;
    }

    &-NavBar {
      position: relative;

      ::before {
        content: '';
        width: 100%;
        height: 50px;
        position: absolute;
        background: #3e3b47;
        border-radius: 10px 10px 0 0;
        z-index: -1;
      }
    }

    &-NavButton {
      color: #999591 !important;
      margin-top: 0;
      top: 0;

      &--prev {
        background: url(${ArrowLeftIcon}) no-repeat center;
        margin-right: 0;
        left: 12px;
        width: 50px;
        height: 50px;
      }

      &--next {
        background: url(${ArrowRightIcon}) no-repeat center;
        right: 12px;
        width: 50px;
        height: 50px;
      }
    }

    &-Month {
      border-collapse: separate;
      border-spacing: 8px;
      margin: 0;
      padding: 0 10px 10px;
    }

    &-Caption {
      line-height: 50px;
      color: #f4ede8;

      > div {
        text-align: center;
      }
    }

    &-Weekday {
      color: #666360;
      font-size: 16px;
    }

    &-Day {
      width: 40px;
      height: 40px;
      transition: all 0.2s ease;
      border-radius: 10px;

      &--today {
        font-weight: normal;
        color: #fff;
      }

      &--available:not(.DayPicker-Day--outside) {
        background: #3e3b47;
        border-radius: 10px;
      }

      &--disabled {
        color: #666360;
        background: transparent !important;
      }

      &--selected:not(.DayPicker-Day--disabled) {
        background: #3ea7a2 !important;
        color: #232129 !important;
      }
    }

    &:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
      background: ${shade(0.2, '#3e3b47')};
    }
  }
`;
