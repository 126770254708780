import styled from 'styled-components';

export const Container = styled.div``;

export const Message = styled.section`
  display: flex;
  max-width: 1120px;
  background-color: #3e3b47;
  border-radius: 20px;
  margin: 24px auto;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  padding: 16px 32px;
  color: #f4ede8;
`;

export const Title = styled.h1`
  color: #3ea7a2;
`;

export const Content = styled.article`
  margin: 32px 0;
`;

export const Info = styled.div`
  display: flex;
  width: 100%;
  padding-top: 8px;
  justify-content: space-between;
  color: #999591;
`;
