import React, { createContext, useCallback, useContext, useState } from 'react';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import api from '../services/apiClient';

interface User {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
}
interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User; // Record<string, unknown>;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(updateData: User): void;
}

interface AuthState {
  token: string;
  user: User;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@GesCondo:token');
    const user = localStorage.getItem('@GesCondo:user');

    if (token && user) {
      const { exp } = jwt_decode<JwtPayload>(token);

      if (exp) {
        const expirationTime = exp * 1000 - 60000;

        if (Date.now() >= expirationTime) {
          localStorage.removeItem('@GesCondo:token');
          localStorage.removeItem('@GesCondo:user');

          history.push('/');
        }
      }

      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', { email, password });

    const { token, user } = response.data;

    localStorage.setItem('@GesCondo:token', token);
    localStorage.setItem('@GesCondo:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@GesCondo:token');
    localStorage.removeItem('@GesCondo:user');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (updateData: User) => {
      setData({
        token: data.token,
        user: updateData,
      });

      localStorage.setItem('@GesCondo:user', JSON.stringify(updateData));
    },
    [data.token],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
