import styled from 'styled-components';
import { Form } from '@unform/web';

interface RowProps {
  columns: number;
}

export const Container = styled(Form)`
  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: var(--text-title);
  }

  /* input {
    width: 100%;
    padding: 0 1.5rem;
    height: 3rem;

    border-radius: 0.25rem;
    border: 1px solid #d7d7d7;
    background: #e7e9ee;

    font-weight: 400;
    font-size: 1rem;

    &::placeholder {
      color: var(--text-body);
    }
  } */

  button[type='submit'] {
    width: 100%;
    padding: 0 1.5rem;
    height: 4rem;

    border-radius: 0.25rem;
    border: 0;
    background: var(--primary);
    color: var(--text-title);
    font-size: 1rem;
    font-weight: 500;

    margin-top: 1rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const Row = styled.div<RowProps>`
  margin: 0.25rem 0;
  display: flex;
  /* display: grid;
  grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};*/
  gap: 0.5rem;
  & + div {
    margin-top: 1rem;
  }
`;
