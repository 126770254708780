import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiClock, FiBox, FiTrash2 } from 'react-icons/fi';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { format, isToday, setHours, parseISO, isAfter } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import 'react-day-picker/lib/style.css';
import Select from '../../components/Select';
import Button from '../../components/Button';
import { useToast } from '../../hooks/toast';

import {
  Container,
  Content,
  Schedule,
  Calendar,
  // NextAppointments,
  Section,
  Appointment,
} from './styles';

import api from '../../services/apiClient';

interface MonthAvailabilityItem {
  day: number;
  available: boolean;
}

interface DayAvailabilityItem {
  hour: number;
  available: boolean;
}

interface SpaceFromAppointment {
  name: string;
  picture_url: string;
}

interface DayAppointmentItem {
  id: string;
  date: string;
  space: SpaceFromAppointment;
}

interface SpaceItem {
  id: string;
  name: string;
}

const Appointments: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [spaces, setSpaces] = useState<SpaceItem[]>([]);
  const [currentSpace, setCurrentSpace] = useState<SpaceItem>();
  const [selectedHour, setSelectedHour] = useState(0);

  const [monthAvailability, setMonthAvailability] = useState<
    MonthAvailabilityItem[]
  >([]);
  const [dayAvailability, setDayAvailability] = useState<DayAvailabilityItem[]>(
    [],
  );
  const [appoitmentsMonth, setAppointmentsMonth] = useState<
    DayAppointmentItem[]
  >([]);
  // const [nextAppointment, setNextAppointment] = useState<DayAppointmentItem>();

  const handleSubmit = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      // const schema = Yup.object().shape({
      //   email: Yup.string()
      //     .required('E-mail obrigatório.')
      //     .email('Digite um e-mail válido'),
      //   password: Yup.string().required('Senha obrigatória'),
      // });

      // await schema.validate(data, {
      //   abortEarly: false,
      // });

      const dataApointment = setHours(selectedDate, selectedHour);

      if (currentSpace) {
        const response = await api.post<DayAppointmentItem>('/appointments', {
          space_id: currentSpace.id,
          date: dataApointment,
        });

        addToast({
          title: 'Agendamento realizado com sucesso',
          description: `${currentSpace.name} agendada para ${format(
            dataApointment,
            "dd/MM/yyyy 'as' hh:mm",
          )}`,
          type: 'success',
        });

        const newDayAvailability = dayAvailability.map(hourItem => {
          return hourItem.hour === selectedHour
            ? { hour: hourItem.hour, available: false }
            : hourItem;
        });

        setAppointmentsMonth(appointments => {
          const newMonthAvailability = [...appointments, response.data];

          console.log(response.data);

          return newMonthAvailability.sort(
            (a: DayAppointmentItem, b: DayAppointmentItem) => {
              return isAfter(parseISO(a.date), parseISO(b.date)) ? 1 : 0;
            },
          );
        });

        setDayAvailability(newDayAvailability);
        setSelectedHour(0);
      }
    } catch (e) {
      addToast({
        title: 'Erro no agendamento',
        description: e.response.data.message,
        type: 'error',
      });
    }
  }, [addToast, currentSpace, selectedDate, selectedHour, dayAvailability]);

  const handleSpaceChange = useCallback(
    ({ target }) => {
      const space = spaces.filter(s => s.id === target.value);
      if (space) {
        setCurrentSpace(space[0]);
      }
    },
    [spaces],
  );

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
    }
  }, []);

  const handleHourChange = useCallback(({ target }) => {
    setSelectedHour(target.value ? Number(target.value) : 0);
  }, []);

  const handleDeleteAppointmente = useCallback(async appointment_id => {
    await api.delete(`/appointments/user/${appointment_id}`);

    console.log(appointment_id);

    setAppointmentsMonth(appointments => {
      return appointments.filter(
        appointment => appointment.id !== appointment_id,
      );
    });
  }, []);

  /** Horas do dia disponível de acordo com o espaço e dia selecionado */
  useEffect(() => {
    if (currentSpace) {
      api
        .get<DayAvailabilityItem[]>(
          `/appointments/space/${currentSpace.id}/day-availability`,
          {
            params: {
              year: selectedDate.getFullYear(),
              month: selectedDate.getMonth() + 1,
              day: selectedDate.getDate(),
            },
          },
        )
        .then(result => {
          setDayAvailability(result.data);
          const firstHour = result.data.find(item => item.available);
          firstHour ? setSelectedHour(firstHour.hour) : setSelectedHour(0);
        });
    }
  }, [currentSpace, selectedDate]);

  // useEffect(() => {
  //   api.get<DayAppointmentItem[]>('/appointments/user/next').then(response => {
  //     setNextAppointment(response.data[0]);
  //   });
  // }, []);

  /** Espaços do condominio */
  useEffect(() => {
    const condominio_id = '56963e80-4feb-4167-8f6c-bc820a9c0613';

    api.get(`/spaces/${condominio_id}`).then(response => {
      setSpaces(response.data);
      setCurrentSpace(response.data[0]);
    });
  }, []);

  /* Dias do mês disponíveis de acordo com o espaço selecionado */
  useEffect(() => {
    if (currentSpace) {
      const espaco_id = currentSpace.id;
      api
        .get(`/appointments/space/${espaco_id}/month-availability`, {
          params: {
            year: currentMonth.getFullYear(),
            month: currentMonth.getMonth() + 1,
          },
        })
        .then(response => {
          setMonthAvailability(response.data);
        });
    }
  }, [currentSpace, currentMonth]);

  /* Agendamentos do mês */
  useEffect(() => {
    api
      .get<DayAppointmentItem[]>('/appointments/user/me', {
        params: {
          year: currentMonth.getFullYear(),
          month: currentMonth.getMonth() + 1,
        },
      })
      .then(response => {
        setAppointmentsMonth(response.data);
      });
  }, [currentMonth]);

  const disabledDays = useMemo(() => {
    const dates = monthAvailability
      .filter(monthDay => !monthDay.available)
      .map(monthDay => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();
        const { day } = monthDay;
        return new Date(year, month, day);
      });

    return dates;
  }, [currentMonth, monthAvailability]);

  const selectedDateAsText = useMemo(() => {
    return format(selectedDate, "'Dia' dd 'de' MMMM", { locale: ptBR });
  }, [selectedDate]);

  const selectedWeekAsText = useMemo(() => {
    return format(selectedDate, 'EEEE', { locale: ptBR });
  }, [selectedDate]);

  const hoursAvailability = useMemo(() => {
    return dayAvailability.map(({ hour }) => {
      return {
        caption: `${hour.toString().padStart(2, '0')}:00`,
        value: hour,
      };
    });
  }, [dayAvailability]);

  const disabledHour = useMemo(() => {
    return dayAvailability
      .map(({ hour, available }) => {
        return {
          available,
          hourDisabled: {
            caption: `${toString().padStart(2, '0')}:00`,
            value: hour,
          },
        };
      })
      .filter(hour => !hour.available)
      .map(hour => hour.hourDisabled);
  }, [dayAvailability]);

  const handleMonthChange = useCallback((month: Date) => {
    setCurrentMonth(month);
  }, []);

  return (
    <Container>
      <Content>
        <Calendar>
          <h2>Novo Agendamento</h2>

          <p>
            {isToday(selectedDate) && <span>Hoje</span>}
            <span>{selectedDateAsText}</span>
            <span>{selectedWeekAsText}</span>
          </p>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Select
              name="espacos"
              id="espacos"
              icon={FiBox}
              onChange={handleSpaceChange}
              options={spaces.map(space => {
                return { caption: space.name, value: space.id };
              })}
            />

            <DayPicker
              weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
              fromMonth={new Date()}
              disabledDays={[...disabledDays]}
              modifiers={{
                available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
              }}
              onMonthChange={handleMonthChange}
              selectedDays={selectedDate}
              onDayClick={handleDateChange}
              months={[
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro',
              ]}
            />

            <h3>Horários Disponíveis</h3>

            <Select
              name="times"
              id="times"
              icon={FiClock}
              onChange={handleHourChange}
              optionsDisabled={disabledHour}
              options={hoursAvailability}
              value={selectedHour}
            />

            <Button type="submit">Agendar</Button>
          </Form>
        </Calendar>
        <Schedule>
          <h2>Agendamentos do mês</h2>

          {/* {nextAppointment && (
            <NextAppointments key={nextAppointment.id}>
              <strong>Próximo agendamento</strong>
              <div>
                <img
                  src={nextAppointment.espaco.picture_url}
                  alt={nextAppointment.espaco.nome}
                />

                <strong>{nextAppointment.espaco}</strong>
                <span>
                  {format(parseISO(nextAppointment.data), "dd/MM 'as' HH:mm")}
                  <FiClock />
                </span>
              </div>
            </NextAppointments>
          )} */}

          <Section>
            {/* <strong>Agendamentos do mês</strong> */}
            {appoitmentsMonth.length === 0 && <p>Sem agendamento neste mês</p>}

            {appoitmentsMonth.map(appointment => (
              <Appointment key={appointment.id}>
                <span>
                  <FiClock />
                  {format(parseISO(appointment.date), "dd/MM 'as' HH:mm")}
                </span>

                <div>
                  <img
                    src={appointment.space.picture_url}
                    alt={appointment.space.name}
                  />
                  <p>{appointment.space.name}</p>
                  <button
                    type="button"
                    onClick={() => handleDeleteAppointmente(appointment.id)}
                  >
                    <FiTrash2 />
                  </button>
                </div>
              </Appointment>
            ))}
          </Section>
        </Schedule>
      </Content>
    </Container>
  );
};

export default Appointments;
