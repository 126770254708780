import Modal from 'react-modal';
import { useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import closeImg from '../../assets/close.svg';
import { Container, Row } from './styles';
import { useToast } from '../../hooks/toast';
import { useCondominiums } from '../../hooks/condominium';
import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../Input';

interface ProfileFormData {
  id: string;
  cnpj: string;
  name: string;
  zip_code: string;
  address: string;
  number: string;
  city: string;
  county: string;
  complement: string;
}

interface NewCondominiumModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  condominium: ProfileFormData;
}

export function NewCondominiumModal({
  isOpen,
  onRequestClose,
  condominium,
}: NewCondominiumModalProps) {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { createCondominium } = useCondominiums();

  async function handleCreateNewCondominium(data: ProfileFormData) {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        cnpj: Yup.string().required('CNPJ é obrigatório'),
        name: Yup.string().required('Nome é obrigatório.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await createCondominium(data);

      onRequestClose();

      addToast({
        type: 'success',
        title: 'Condomínio salvo.',
        description: `Condomínio ${data.name} salvo com sucesso!`,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }

      addToast({
        type: 'error',
        title: 'Erro ao salvar',
        description:
          'Ocorreu um erro ao tentar salvar este condomínio, tente novamente',
      });
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <img src={closeImg} alt="Fechar" />
      </button>

      <Container
        onSubmit={handleCreateNewCondominium}
        ref={formRef}
        initialData={condominium}
      >
        <h2>Cadastro Condomínio</h2>
        <Row columns={2}>
          <Input
            name="cnpj"
            placeholder="CNPJ"
            containerStyle={{ width: '15rem' }}
          />
          <Input name="name" placeholder="Nome" />
        </Row>
        <Row columns={3}>
          <Input
            name="zip_code"
            placeholder="CEP"
            containerStyle={{ width: '10rem' }}
          />
          <Input name="address" placeholder="Endereço" />
          <Input
            name="number"
            placeholder="Número"
            containerStyle={{ width: '12rem' }}
          />
        </Row>
        <Row columns={3}>
          <Input name="city" placeholder="Cidade" />
          <Input
            name="county"
            placeholder="Estado"
            containerStyle={{ width: '15rem' }}
          />
          <Input name="complement" placeholder="Complemento" />
        </Row>

        <Input name="id" placeholder="Id" hidden />

        <button type="submit">Salvar</button>
      </Container>
    </Modal>
  );
}
