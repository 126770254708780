import React, { useState } from 'react';
import { FiTrash, FiEdit } from 'react-icons/fi';

import Button from '../../components/Button';
import { useCondominiums } from '../../hooks/condominium';
import { NewCondominiumModal } from '../../components/NewCondominiumModal';

import { Container } from './styles';
import { useToast } from '../../hooks/toast';

interface ProfileFormData {
  id: string;
  cnpj: string;
  name: string;
  zip_code: string;
  address: string;
  number: string;
  city: string;
  county: string;
  complement: string;
}

const Condominium: React.FC = () => {
  const { condominiums, deleteCondominium } = useCondominiums();
  const [condo, setCondo] = useState<ProfileFormData>({} as ProfileFormData);
  const [isOpen, setIsOpen] = useState(false);
  const { addToast } = useToast();

  function handleCloseNewCondominiumModal() {
    setIsOpen(false);
  }

  function handleOpenNewCondominiumModal() {
    setCondo({} as ProfileFormData);
    setIsOpen(true);
  }

  async function handleDeleteCondominium(id: string) {
    try {
      await deleteCondominium(id);

      addToast({
        type: 'success',
        title: 'Condomínio excluído.',
        description: `Condomínio excluído com sucesso!`,
      });
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Falha na excllusão.',
        description: 'Não é possível excluir este condomínio',
      });
    }
  }

  async function handleUpdateCondominium(condominium: ProfileFormData) {
    setCondo(condominium);
    setIsOpen(true);
  }

  return (
    <Container>
      <Button type="button" onClick={handleOpenNewCondominiumModal}>
        Novo
      </Button>

      <table>
        <thead>
          <tr>
            <th>Cnpj</th>
            <th>Nome</th>
            <th>Endereço</th>
            <th>Número</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {condominiums.map(condominium => {
            return (
              <tr key={condominium.id}>
                <td>{condominium.cnpj}</td>
                <td>{condominium.name}</td>
                <td>{condominium.address}</td>
                <td>{condominium.number}</td>
                <td>
                  <div>
                    <button
                      type="button"
                      onClick={() => handleUpdateCondominium(condominium)}
                    >
                      <FiEdit size={20} />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleDeleteCondominium(condominium.id)}
                    >
                      <FiTrash size={20} />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <NewCondominiumModal
        isOpen={isOpen}
        onRequestClose={handleCloseNewCondominiumModal}
        condominium={condo}
      />
    </Container>
  );
};

export default Condominium;
